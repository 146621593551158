import React, { useEffect, useRef } from "react";
import ArrowDown from "../svg/arrowDown";
import Styles from "./pagination.module.scss";

function Pagination({
  activePageNumber,
  total,
  postPerPage,
  navigateToPage,
  nextButtonShow,
}: any) {
  const paginationRef = useRef<HTMLDivElement | null>(null);

  const pages = [];
  for (let i = 1; i <= Math.ceil(total / postPerPage); i++) {
    pages.push(i);
  }

  const currentIndex = pages.findIndex(
    (pageNumber) => pageNumber === activePageNumber
  );
  const pageRef = useRef<any>([]);

  useEffect(() => {
    paginationRef?.current?.scrollTo({
      left: currentIndex * 30,
      behavior: "smooth",
    });
  }, [currentIndex]);

  function gotToPreviousPage() {
    navigateToPage(activePageNumber - 1);
  }

  function goToNextPage() {
    navigateToPage(activePageNumber + 1);
  }

  const handleClick = (pageNumber: number) => {
    navigateToPage(pageNumber);
    paginationRef?.current?.scrollTo({
      left: 1200,
      behavior: "smooth",
    });
  };

  const handleJumpToStart = () => {
    navigateToPage(1);
  };

  const handleJumpToEnd = () => {
    navigateToPage(pages.length);
  };

  return (
    <div className="d-inline-block">
      <div className="d-flex align-items-center">
        {activePageNumber !== 1 && (
          <>
            <div
              className={`mx-2 mt-2 ${Styles.arrowDivs}`}
              onClick={handleJumpToStart}
            >
              <ArrowDown className={`${Styles.rotateLeft} ${Styles.svgFill}`} />
              <ArrowDown className={`${Styles.rotateLeft} ${Styles.svgFill}`} />
            </div>

            <div
              className={`mt-2 mr-3 ${Styles.rotateLeft} ${Styles.arrowDivs}`}
              onClick={() => {
                gotToPreviousPage();
              }}
            >
              <ArrowDown className={Styles.svgFill} />
            </div>
          </>
        )}

        <div
          className={"d-flex"}
          ref={paginationRef}
          style={{
            maxWidth: "50vw",
            overflowX: "auto",
            overflowY: "hidden",
            padding: "10px 0",
          }}
        >
          {pages.map((pageNumber, index: number) => {
            return (
              <div
                className="px-2"
                key={index}
                ref={(pageNumber) => (pageRef.current[index] = pageNumber)}
              >
                <div
                  onClick={() => {
                    handleClick(pageNumber);
                  }}
                  className={`d-flex justify-content-center align-items-center ${
                    activePageNumber === pageNumber
                      ? Styles.pageNumberActive
                      : Styles.pageNumberInActive
                  }`}
                >
                  <p className={`m-0 ${Styles.pageNumberText}`}>{pageNumber}</p>
                </div>
              </div>
            );
          })}
        </div>

        {!nextButtonShow &&
          activePageNumber !== pages.length &&
          pages.length > 0 && (
            <>
              <div
                className={`mt-2 ml-3 ${Styles.rotateRight} ${Styles.arrowDivs}`}
                onClick={() => {
                  goToNextPage();
                }}
              >
                <ArrowDown className={Styles.svgFill} />
              </div>

              <div
                className={`mx-2 mt-2 ${Styles.arrowDivs}`}
                onClick={handleJumpToEnd}
              >
                <ArrowDown
                  className={`${Styles.rotateRight} ${Styles.svgFill} `}
                />
                <ArrowDown
                  className={`${Styles.rotateRight} ${Styles.svgFill}`}
                />
              </div>
            </>
          )}
      </div>
    </div>
  );
}

export default Pagination;
