import React, { useCallback, useContext, useEffect } from "react";
import Button from "../../../../../../shared/button/button";
import { MEDIUM } from "../../../../../../shared/buttonSize";
import {
  cancelButtonHoverStyle,
  cancelButtonStyle,
  primaryButtonHoverStyle,
  primaryButtonStyle,
} from "../../../../../../shared/buttonStyles";
import Styles from "./addPaymentModal.module.scss";
import popUpStyles from "../../../../../../shared/popup.module.scss";
import sharedStyle from "../../../../../../shared/sharedStyle.module.scss";
import RadioButton from "../../../../../../shared/radio-button/radioButton";
import Input from "../../../../../../shared/input/input";
import ErrorMessage from "../../../../../../shared/error-message/errorMessage";
import AccountWallet from "./account-wallet/accountWallet";
import ExternalPayment from "./external-payment/externalPayment";
import { ACCENTCOLOR } from "../../../../../../shared/colors";
import Loading from "../../../../../../shared/loading/loading";
import { callGetApi, callPutApi } from "../../../../../../api/axios";
import { useParams } from "react-router-dom";
import { ToastContext } from "../../../../../../context/toastContext";
import NoPayment from "./no-payment/noPayment";

// type
interface apiBodyType {
  order_type: string;
  payment_mode: string;
  amount: string;
  reason?: string; // Optional
  bank_ref_number?: string; // Optional
}

function AddPaymentModal({
  onCancel,
  onProceed,
  planSubscribed,
  country_code,
}: any) {
  const { id }: any = useParams();
  const type = {
    balance_topup: "balance_topup",
    plan_payment: "plan_payment",
  };
  const method = {
    NEFT: "NEFT",
    IMPS: "IMPS",
    RTGS: "RTGS",
    ADVANCE_PAYMENT: "ADVANCE_PAYMENT",
  };
  const adjustment_method = {
    account_wallet: "account_wallet",
    external_payment: "external_payment",
    no_payment: "no_payment",
  };
  const [paymentType, setPaymentType] = React.useState(type.balance_topup);
  const [planAdjustmentMethod, setPlanAdjustmentMethod] = React.useState("");
  const [paymentMethod, setPaymentMethod] = React.useState("");
  const [referenceNumber, setReferenceNumber] = React.useState("");
  const [reason, setReason] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [error, setError] = React.useState({
    reference_number: "",
    reason_for_advance_payment: "",
    amount: "",
  });
  const dispatch = useContext(ToastContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [planPaymentPending, setPlanPaymentPending] = React.useState({
    amount: 0,
    order_id: "",
    plan_id: "",
    plan_name: "",
    validity: 0,
    wallet_balance: 0,
    wallet_amount: 0,
  });
  const [noOrderGenerated, setNoOrderGenerated] = React.useState("");
  const [updatedWalletBalance, setUpdatedWalletBalance] = React.useState(0);
  const numberRegexp = /[^\0-9]/;
  const loading = (
    <div
      style={{ height: "250px" }}
      className="d-flex align-items-center justify-content-center"
    >
      <Loading loadingColor={ACCENTCOLOR}></Loading>
    </div>
  );
  const alphaNumericRegexp = /^[A-Z0-9]+$/;

  useEffect(() => {
    if (!planSubscribed?.currentPlan?.plan_name) {
      setPaymentType("");
    }
    if (
      !planSubscribed?.currentPlan?.plan_name &&
      planSubscribed?.requestedPlan
    ) {
      setPaymentType(type.plan_payment);
    }
  }, [planSubscribed, type.plan_payment]);

  useEffect(() => {
    async function getOrgPendingPaymentInfo() {
      setIsLoading(true);
      try {
        const { data }: any = await callGetApi(
          `/organisations/${id}?data=pending_plan_info`
        );
        setPlanPaymentPending(data);
        setIsLoading(false);
      } catch (err: any) {
        setIsLoading(false);
        setNoOrderGenerated("No Plan Subscripiton found in pending state");
      }
    }
    getOrgPendingPaymentInfo();
  }, [id, dispatch]);

  const getBillingInfoOfOrg = useCallback(async () => {
    try {
      const response: any = await callGetApi(
        `organisations/${id}?data=billing_info`
      );
      const { wallet_balance } = response.data;
      setUpdatedWalletBalance(wallet_balance);
    } catch (err: any) {
      console.log(err);
    }
  }, [id, dispatch]);

  useEffect(() => {
    getBillingInfoOfOrg();
  }, [getBillingInfoOfOrg]);

  function checkReferenceNumber() {
    if (!referenceNumber && reason) return;

    if (!referenceNumber) {
      setError((error) => ({
        ...error,
        reference_number: "Reference Number cannot be empty",
      }));
      return false;
    }

    const reference_number = referenceNumber.split(",");
    const isValidReferenceNumber = reference_number.map((number, index) => {
      if (!alphaNumericRegexp.test(number)) {
        setError((error) => ({
          ...error,
          reference_number: "Reference Number should be all caps and numeric",
        }));
        return false;
      }
      return reference_number.every((val, key) => {
        if (number === val && index !== key) {
          setError((error) => ({
            ...error,
            reference_number:
              "Duplicate! Please enter correct reference numbers.",
          }));
          return false;
        }
        return true;
      });
    });
    return isValidReferenceNumber.every(Boolean);
  }

  function checkReason() {
    if (reason === "") {
      setError({
        ...error,
        reason_for_advance_payment: "Reason cannot be empty",
      });
    }
  }

  function checkAmount() {
    if (numberRegexp.test(amount)) {
      setError((error) => ({
        ...error,
        amount: "Enter a valid amount",
      }));
      return false;
    }

    if (Number(amount) <= 0) {
      setError((error) => ({
        ...error,
        amount: "Amount should not be negative or zero",
      }));
      return false;
    }

    if (amount[0] === "0") {
      setError((error) => ({
        ...error,
        amount: "Enter a valid amount",
      }));
      return false;
    }

    if (!Number.isInteger(+amount)) {
      setError((error) => ({
        ...error,
        amount: "No decimal values allowed",
      }));
      return false;
    }

    return true;
  }

  function checkProceedDisablity() {
    if (!referenceNumber && !reason) return true;
    else if (!amount) return true;
    else if (
      error.amount ||
      error.reference_number ||
      error.reason_for_advance_payment
    )
      return true;

    return false;
  }

  async function handleBallanceTopUp(value: string) {
    if (!checkReferenceNumber() && !reason) return;
    if (!checkAmount()) return;

    setButtonLoading(true);
    try {
      const apiBody: apiBodyType = {
        order_type: "BALANCE_TOPUP",
        payment_mode: paymentMethod,
        amount: amount,
      };

      if (paymentMethod === method.ADVANCE_PAYMENT) {
        apiBody.reason = reason;
      } else {
        apiBody.bank_ref_number = referenceNumber;
      }

      await callPutApi(`organisations/${id}?action=add_custom_payment`, {
        ...apiBody,
      });
      setButtonLoading(false);
      onProceed(value, amount);
    } catch (err: any) {
      setButtonLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: err,
          message: err.response.data.err,
        },
      });
    }
  }

  const handleChangePaymentMethod = (paymentType: any) => {
    setPaymentMethod(paymentType);
    setError({
      ...error,
      reference_number: "",
      reason_for_advance_payment: "",
      amount: "",
    });
    setReason("");
    setReferenceNumber("");
    setAmount("");
  };

  return (
    <div
      className={`d-flex align-items-center justify-content-center ${popUpStyles.overlay}`}
    >
      <div className={`${popUpStyles.popUpContent} ${Styles.card}`}>
        <div className={`p-4 ${sharedStyle.cardHeaderBack}`}>
          <p className={`mb-0 ${sharedStyle.cardHeader}`}>
            {" "}
            {planAdjustmentMethod === adjustment_method.no_payment
              ? "No Payment"
              : "Add Payment"}
          </p>
        </div>
        {isLoading ? (
          loading
        ) : (
          <div>
            <div className="px-4 py-2 mx-2">
              {planAdjustmentMethod === "" && (
                <>
                  {/* Select a Payment Option from here  */}
                  <div className="container-fluid px-0 py-2">
                    <div className="row">
                      <div className="col-12">
                        <p className={`mb-0 ${Styles.label}`}>Payment Type</p>
                      </div>
                      <div className="col-md-6 py-2">
                        <RadioButton
                          disabled={!planSubscribed?.currentPlan?.plan_name}
                          label="Balance Topup"
                          type="radio"
                          checked={paymentType === type.balance_topup}
                          name="payment_type"
                          onChange={() => setPaymentType(type.balance_topup)}
                        />
                        {!planSubscribed?.currentPlan?.plan_name && (
                          <ErrorMessage>
                            No Active plan subscribed to add balance
                          </ErrorMessage>
                        )}
                      </div>
                      <div className="col-md-6 py-2">
                        <RadioButton
                          label="Plan Payment"
                          type="radio"
                          disabled={planPaymentPending.order_id === ""}
                          checked={paymentType === type.plan_payment}
                          name="payment_type"
                          onChange={() => setPaymentType(type.plan_payment)}
                        />
                        {noOrderGenerated && (
                          <ErrorMessage>{noOrderGenerated}</ErrorMessage>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* {if the payment type is balance top up} */}
                  {paymentType === type.balance_topup && (
                    <div>
                      <div className="container-fluid px-0 py-2">
                        <div className="row">
                          <div className="col-12">
                            <p className={`mb-0 ${Styles.label}`}>Method</p>
                          </div>
                          <div className="col-6 py-2">
                            <RadioButton
                              label="NEFT"
                              disabled={country_code !== "IN"}
                              type="radio"
                              checked={paymentMethod === method.NEFT}
                              name="payment_method"
                              onChange={() =>
                                handleChangePaymentMethod(method.NEFT)
                              }
                            />
                          </div>
                          <div className="col-6  py-2">
                            <RadioButton
                              label="IMPS"
                              type="radio"
                              checked={paymentMethod === method.IMPS}
                              name="payment_method"
                              onChange={() =>
                                handleChangePaymentMethod(method.IMPS)
                              }
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6  py-2">
                            <RadioButton
                              label="RTGS"
                              disabled={country_code !== "IN"}
                              type="radio"
                              checked={paymentMethod === method.RTGS}
                              name="payment_method"
                              onChange={() =>
                                handleChangePaymentMethod(method.RTGS)
                              }
                            />
                          </div>
                          <div className="col-6  py-2">
                            <RadioButton
                              id="add-payment-advance"
                              label="Advance Payment"
                              disabled={country_code !== "IN"}
                              type="radio"
                              checked={paymentMethod === method.ADVANCE_PAYMENT}
                              name="payment_method"
                              onChange={() =>
                                handleChangePaymentMethod(
                                  method.ADVANCE_PAYMENT
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>

                      {(paymentMethod === method.NEFT ||
                        paymentMethod === method.IMPS ||
                        paymentMethod === method.RTGS ||
                        paymentMethod === method.ADVANCE_PAYMENT) && (
                        <div className="container-fluid px-0">
                          <div className="row">
                            <div className="col-md-12">
                              {paymentMethod === method.ADVANCE_PAYMENT ? (
                                <Input
                                  id="reason_for_advance_payment"
                                  type="text"
                                  value={reason}
                                  name="reason_for_advance_payment"
                                  autoComplete="off"
                                  hasError={error.reason_for_advance_payment}
                                  placeholder="Enter Reason for the Payment"
                                  labelname="Payment Reason"
                                  onChange={(event: any) => {
                                    setReason(event.target.value);
                                    setError((error) => ({
                                      ...error,
                                      reason_for_advance_payment: "",
                                    }));
                                  }}
                                  onBlur={checkReason}
                                />
                              ) : (
                                <Input
                                  id="reference_number"
                                  type="text"
                                  value={referenceNumber}
                                  name="reference_number"
                                  autoComplete="off"
                                  hasError={error.reference_number}
                                  placeholder="Enter Reference Number"
                                  labelname="Reference Number"
                                  onChange={(event: any) => {
                                    setReferenceNumber(event.target.value);
                                    setError((error) => ({
                                      ...error,
                                      reference_number: "",
                                    }));
                                  }}
                                  onBlur={checkReferenceNumber}
                                />
                              )}

                              {(error.reference_number ||
                                error.reason_for_advance_payment) && (
                                <ErrorMessage>
                                  {error.reference_number ||
                                    error.reason_for_advance_payment}
                                </ErrorMessage>
                              )}
                            </div>
                            <div className="col-md-12 pb-2">
                              <Input
                                type="number"
                                name="amount"
                                id="amount"
                                value={amount}
                                autoComplete="off"
                                hasError={error.amount}
                                placeholder="Enter exact Amount credited by customer"
                                labelname="Amount"
                                onChange={(event: any) => {
                                  setAmount(event.target.value);
                                  setError((error) => ({
                                    ...error,
                                    amount: "",
                                  }));
                                }}
                                onBlur={checkAmount}
                              />
                              {error.amount && (
                                <ErrorMessage>{error.amount}</ErrorMessage>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {/* Footer Section  */}
                      <div
                        className="py-4 my-2 d-flex align-items-center justify-content-center"
                        style={{ borderTop: "1px solid #ECEEEF" }}
                      >
                        <div className="px-2">
                          <Button
                            size={MEDIUM}
                            style={cancelButtonStyle}
                            disabled={buttonLoading}
                            hoveredStyle={cancelButtonHoverStyle}
                            onClick={() => onCancel()}
                          >
                            Cancel
                          </Button>
                        </div>
                        <div className="px-2">
                          <Button
                            size={MEDIUM}
                            disabled={checkProceedDisablity() || buttonLoading}
                            isLoading={buttonLoading}
                            style={primaryButtonStyle}
                            hoveredStyle={primaryButtonHoverStyle}
                            onClick={() =>
                              handleBallanceTopUp("Balance_Top_Up")
                            }
                          >
                            Proceed
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* else payment type is plan payment  */}
                  {paymentType === type.plan_payment && (
                    <div className="container-fluid px-0 py-2">
                      <div className="row">
                        <div className="col-12">
                          <p className={`mb-0 ${Styles.label}`}>
                            Adjustment Method
                          </p>
                        </div>
                        <div className="col-md-4 py-2">
                          <RadioButton
                            label="Account Wallet"
                            type="radio"
                            checked={
                              planAdjustmentMethod ===
                              adjustment_method.account_wallet
                            }
                            name="adjustment_method"
                            onChange={() =>
                              setPlanAdjustmentMethod(
                                adjustment_method.account_wallet
                              )
                            }
                          />
                        </div>
                        <div className="col-md-4 py-2">
                          <RadioButton
                            label="External Payment"
                            type="radio"
                            checked={
                              planAdjustmentMethod ===
                              adjustment_method.external_payment
                            }
                            name="adjustment_method"
                            onChange={() =>
                              setPlanAdjustmentMethod(
                                adjustment_method.external_payment
                              )
                            }
                          />
                        </div>
                        <div className="col-md-4 py-2">
                          <RadioButton
                            label="No Payment"
                            type="radio"
                            checked={
                              planAdjustmentMethod ===
                              adjustment_method.no_payment
                            }
                            disabled={updatedWalletBalance <= 0 ? true : false}
                            name="adjustment_method"
                            onChange={() =>
                              setPlanAdjustmentMethod(
                                adjustment_method.no_payment
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {!planSubscribed?.currentPlan?.plan_name && (
                    <div
                      className="py-4 my-2 d-flex align-items-center justify-content-center"
                      style={{ borderTop: "1px solid #ECEEEF" }}
                    >
                      <div className="px-2">
                        <Button
                          size={MEDIUM}
                          style={cancelButtonStyle}
                          hoveredStyle={cancelButtonHoverStyle}
                          onClick={() => onCancel()}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  )}
                </>
              )}
              {planAdjustmentMethod === adjustment_method.account_wallet && (
                <AccountWallet
                  id={id}
                  planPaymentPending={planPaymentPending}
                  back={() => setPlanAdjustmentMethod("")}
                  onCancel={onCancel}
                  onUpdateAccountWallet={onProceed}
                />
              )}
              {planAdjustmentMethod === adjustment_method.external_payment && (
                <ExternalPayment
                  id={id}
                  country_code={country_code}
                  planPaymentPending={planPaymentPending}
                  back={() => setPlanAdjustmentMethod("")}
                  reference_number={(value: string) =>
                    setReferenceNumber(value)
                  }
                  onCancel={onCancel}
                  onProceed={onProceed}
                />
              )}

              {planAdjustmentMethod === adjustment_method.no_payment && (
                <NoPayment
                  id={id}
                  planPaymentPending={planPaymentPending}
                  onCancel={onCancel}
                  back={() => setPlanAdjustmentMethod("")}
                  onProceed={onProceed}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AddPaymentModal;
