import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { callGetApi, callPostApi } from "../../../api/axios";
import { ToastContext } from "../../../context/toastContext";
import Button from "../../../shared/button/button";
import { COMPRESSED } from "../../../shared/buttonSize";
import {
  primaryButtonHoverStyle,
  primaryButtonStyle,
} from "../../../shared/buttonStyles";
import { ACCENTCOLOR } from "../../../shared/colors";
import FilterDropdown from "../../../shared/inTable-filter-dropdown/filterDropdown";
import Loading from "../../../shared/loading/loading";
import Pagination from "../../../shared/pagination/pagination";
import sharedStyle from "../../../shared/sharedStyle.module.scss";
import ArrowDown from "../../../shared/svg/arrowDown";
import Close from "../../../shared/svg/close";
import Sort from "../../../shared/svg/sort";
import { ERROR } from "../../../utils/toastType";
import Styles from "./plans.module.scss";
import Modal from "react-bootstrap/Modal";
import ProductPriceFilter from "./product-price-filter/productPriceFilter";
import PopupModal from "../../../shared/popupModal/popupModal";
import FilterTagModal from "../../../shared/filter-tag/filterTagModal";
import tagStyles from "../../../shared/filter-tag/filterTagModal.module.scss";
import PlanCustomer from "./plan-customers/planCustomer";
import { PermissionContext } from "../../../context/permissionContext";
import { plans } from "../../../constants/navItems";

type Plan = {
  _id: string;
  createdAt: string;
  customer_count: number;
  is_custom_plan: boolean;
  plan_code: string;
  plan_name: string;
  plan_type: string;
  prepaid_recharge_amount: number;
  plan_validity: number;
  updatedAt: string;
  status: string;
};

function Plans() {
  const sortTypes = {
    ascending: "Ascending",
    descending: "Descending",
  };
  const sortingColumns = {
    plan_name: "name",
    amount: "amount",
    validity: "validity",
    type: "type",
    status: "status",
  };
  const planTypes = {
    PREPAID: "Prepaid",
    POSTPAID: "Postpaid",
  };
  const { assets, permissions: permissionsType } =
    useContext(PermissionContext);
  const grant = assets[plans][plans].grant;
  const permissions = assets[plans][plans].permissions;
  const [loading, setLoading] = useState(false);
  const [planList, setPlanList] = useState<Plan[]>([]);
  const [defaultPlanList, setDefaultPlanList] = useState<Plan[]>([]);
  const [filter, setFilters] = useState<any[]>([]);
  const [planNameFilter, setPlanNameFilter] = useState<String[]>([]);
  const [amountFilter, setAmountFilter] = useState<String[]>([]);
  const [validityFilter, setValidityFilter] = useState<String[]>([]);
  const [typeFilter, setTypeFilter] = useState<String[]>([]);
  const [statusFilter, setStatusFilter] = useState<String[]>([]);
  const [planCodeFilter, setPlanCodeFilter] = useState<String[]>([]);
  const [planLength, setPlanLength] = useState(0);
  const [listOfPlanNames, setListOfPlanNames] = useState<String[]>([]);
  const [loadingFilter, setLoadingFilter] = React.useState(false);
  const [listOfAmounts, setListOfAmounts] = useState<String[]>([]);
  const [listOfValidDurations, setListOfValidDurations] = useState<String[]>(
    []
  );
  const [listOfPlanTypes, setListOfPlanTypes] = useState<String[]>([]);
  const [listOfPlanStatuses, setListOfPlanStatuses] = useState<String[]>([]);
  const [listOfPlanCodes, setListOfPlanCodes] = useState<String[]>([]);
  const [allProductsPricings, setAllProductsPricings] = useState<any>([]);
  const [filterBasedOnSorting, setFilterBasedOnSorting] = React.useState({
    sortType: "",
    sortingColumn: "",
  });
  const [activePageNumber, setActivePageNumber] = useState(1);
  const [postPerPage] = useState(20);
  const [allOrgs, setAllOrgs] = useState<any>([]);
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const [planName, setPlanName] = useState("");
  const [customersLoading, setCustomersLoading] = useState(false);
  const [customerListIndex, setCustomerListIndex] = useState<number>();
  const [planData, setPlanData] = useState<Plan[]>([]);
  const [filterPlanCheck, setFilterPlanCheck] = useState(false);
  const dispatch = useContext(ToastContext);
  const [loadingFilterByProduct, setLoadingFilterByProduct] = useState(false);

  const [showPopupModal, setShowPopupModal] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState<any[]>([]);
  const [allZoopPlans, setAllZoopPlans] = React.useState<any>();

  useEffect(() => {
    setFilters([
      ...planNameFilter,
      ...amountFilter,
      ...planCodeFilter,
      ...validityFilter,
      ...statusFilter,
      ...typeFilter,
    ]);
    setActivePageNumber(1);
  }, [
    planNameFilter,
    amountFilter,
    planCodeFilter,
    validityFilter,
    statusFilter,
    typeFilter,
  ]);

  const handleClose = () => setShow(false);

  const handleShow = (
    e: any,
    planId: string,
    planName: string,
    index: number
  ) => {
    e.stopPropagation();
    e.preventDefault();
    setCustomersLoading(true);
    setCustomerListIndex(index);
    setPlanName(planName);
    getAllCustomersForPlan(planId);
  };

  function capitalizeFirstChar(status: string) {
    return status.charAt(0).toUpperCase() + status.slice(1);
  }

  async function getPlansPricing() {
    try {
      setLoadingFilterByProduct(true);
      setLoadingFilter(true);
      const { data }: any = await callGetApi(
        `/plans?data=get_plans_for_product_pricing`
      );
      setLoadingFilterByProduct(false);
      setLoadingFilter(false);
      const { plans } = data;
      const all_plans_products_pricings = plans
        .filter((plan: any) => plan !== null)
        .map((plan: any) => ({
          planID: plan._id,
          planPricing: plan.pricing,
        }));

      setPlanData(plans.filter((plan: any) => plan !== null));

      setAllProductsPricings([...all_plans_products_pricings]);
      setAllZoopPlans(plans.filter((plan: any) => plan !== null));
    } catch (err: any) {
      setLoadingFilter(false);
      setLoadingFilterByProduct(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: err.response.data.err,
        },
      });
    }
  }

  useEffect(() => {
    getPlansPricing();
  }, []);

  async function getAllPlans(pageNumber: any) {
    setLoading(true);
    try {
      const { data }: any = await callPostApi(
        `/plans/planfilter?data=get_all_plans&per_page=${postPerPage}&page_no=${pageNumber}`,
        {
          filter: {
            ...(planNameFilter &&
              planNameFilter.length > 0 && { plan_name: planNameFilter }),
            ...(statusFilter &&
              statusFilter.length > 0 && { status: statusFilter }),
            ...(validityFilter &&
              validityFilter.length > 0 && {
                plan_validity: validityFilter.map((validity: any) => {
                  return validity.split(" ")[0];
                }),
              }),
            ...(typeFilter &&
              typeFilter.length > 0 && {
                plan_type: typeFilter.map((type: any) => {
                  return type.toUpperCase();
                }),
              }),
            ...(planCodeFilter &&
              planCodeFilter.length > 0 && { plan_code: planCodeFilter }),
            ...(amountFilter &&
              amountFilter.length > 0 && {
                prepaid_recharge_amount: amountFilter,
              }),
            ...(selectedFilters &&
              selectedFilters.length > 0 && { product: selectedFilters }),
          },
        }
      );
      const { plans, planCount } = data;
      const planList = plans.map((plan: Plan) => ({
        id: plan._id,
        name: plan.plan_name,
        code: plan.plan_code,
        version: "-",
        validity: `${plan.plan_validity} Months`,
        type:
          plan.plan_type.toLowerCase() === planTypes.PREPAID.toLowerCase()
            ? planTypes.PREPAID
            : planTypes.POSTPAID,
        status: capitalizeFirstChar(plan.status.toLowerCase()),
        customer_count: plan.customer_count,
        amount: plan.prepaid_recharge_amount,
      }));
      setPlanList(planList);
      setPlanLength(planCount);
      setDefaultPlanList(planList);
      setLoading(false);
      setFilterPlanCheck(true);
    } catch (err: any) {
      setLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: err.response.data.err,
        },
      });
    }
  }
  useEffect(() => {
    getAllPlans(1);
  }, [dispatch, selectedFilters]);

  async function getAllCustomersForPlan(planId: string) {
    try {
      setError("");
      const { error, data }: any = await callGetApi(
        `/plans/getOrgDetailsByPlanId/${planId}`
      );
      if (error) throw new Error(error);
      else setAllOrgs([...data]);
      setCustomersLoading(false);
      setShow(true);
    } catch (error: any) {
      setError(error.toString());
      setCustomersLoading(false);
      setShow(true);
    }
  }

  useEffect(() => {
    if (
      planNameFilter.length > 0 ||
      statusFilter.length > 0 ||
      validityFilter.length > 0 ||
      typeFilter.length > 0 ||
      planCodeFilter.length > 0 ||
      amountFilter.length > 0
    ) {
      getAllPlans(1);
      setActivePageNumber(1);
    }
  }, [
    planNameFilter,
    statusFilter,
    validityFilter,
    typeFilter,
    planCodeFilter,
    amountFilter,
  ]);

  const indicator = (filteredArrayName: String[]) => {
    return (
      <div className="px-1">
        <div
          className={`${Styles.indicator} d-flex align-items-center justify-content-center`}
        >
          <p className={`mb-0 ${Styles.indicatorText}`}>
            {filteredArrayName.length}
          </p>
        </div>
      </div>
    );
  };

  function clearAllFilters() {
    setPlanNameFilter([]);
    setAmountFilter([]);
    setPlanCodeFilter([]);
    setValidityFilter([]);
    setTypeFilter([]);
    setStatusFilter([]);
  }

  // Sort the column in descending order
  function sortDescending(leftParam: any, rightParam: any) {
    let comparison = 0;
    if (leftParam > rightParam) {
      comparison = -1;
    } else if (leftParam < rightParam) {
      comparison = 1;
    }
    return comparison;
  }

  // Sort the column in ascending order
  function sortAscending(leftParam: any, rightParam: any) {
    let comparison = 0;
    if (leftParam > rightParam) {
      comparison = 1;
    } else if (leftParam < rightParam) {
      comparison = -1;
    }
    return comparison;
  }

  // decide type of sort for specific column
  function sort(type: string, columnName: string) {
    const sortedArray = planList.slice().sort((a: any, b: any) => {
      let leftParam;
      let rightParam;
      if (columnName === sortingColumns.amount) {
        leftParam = Number(a[columnName]);
        rightParam = Number(b[columnName]);
      } else if (columnName === sortingColumns.validity) {
        leftParam = Number(a[columnName].split(" ")[0]);
        rightParam = Number(b[columnName].split(" ")[0]);
      } else {
        leftParam = a[columnName].toLowerCase();
        rightParam = b[columnName].toLowerCase();
      }
      if (type === sortTypes.ascending) {
        return sortAscending(leftParam, rightParam);
      } else {
        return sortDescending(leftParam, rightParam);
      }
    });
    setPlanList(sortedArray);
  }

  // decide which sorting to apply based upon column name
  function applySorting(columnName: string) {
    if (
      filterBasedOnSorting.sortType === sortTypes.descending &&
      filterBasedOnSorting.sortingColumn === columnName
    ) {
      setFilterBasedOnSorting((filterBasedOnSorting) => ({
        ...filterBasedOnSorting,
        sortType: "",
        sortingColumn: columnName,
      }));
      setPlanList(defaultPlanList);
    } else if (
      filterBasedOnSorting.sortType === sortTypes.ascending &&
      filterBasedOnSorting.sortingColumn === columnName
    ) {
      setFilterBasedOnSorting((filterBasedOnSorting) => ({
        ...filterBasedOnSorting,
        sortType: sortTypes.descending,
        sortingColumn: columnName,
      }));
      sort(sortTypes.descending, columnName);
    } else {
      setFilterBasedOnSorting((filterBasedOnSorting) => ({
        ...filterBasedOnSorting,
        sortType: sortTypes.ascending,
        sortingColumn: columnName,
      }));
      sort(sortTypes.ascending, columnName);
    }
  }
  // get all the names of the Plans in the given list
  function getAllPlanNames() {
    const planName: any[] = planData.map((plan: any) => plan?.plan_name);
    return setListOfPlanNames([...new Set(planName)]);
  }
  // get all amounts of plans
  function getAllAmounts() {
    const planAmount: any[] = planData.map(
      (plan: any) => plan?.prepaid_recharge_amount.toString()
    );
    return setListOfAmounts([...new Set(planAmount)]);
  }
  // get all plan codes
  function getAllPlanCodes() {
    const planCode: any[] = planData.map(
      (plan: any) => plan?.plan_code.toString()
    );
    return setListOfPlanCodes([...new Set(planCode)]);
  }

  function getAllValidDurations() {
    const uniqueDurations = Array.from(
      new Set(planData.map((plan: any) => `${plan?.plan_validity} Months`))
    );
    // Set the state with the unique list of durations
    setListOfValidDurations(uniqueDurations);
  }

  function getAllPlanTypes() {
    const dataTypes = planData.map((plan: any) =>
      plan.plan_type.toLowerCase() === planTypes.PREPAID.toLowerCase()
        ? planTypes.PREPAID
        : planTypes.POSTPAID
    );
    return setListOfPlanTypes([...new Set(dataTypes)]);
  }

  function getAllPlanStatuses() {
    return setListOfPlanStatuses([
      ...new Set(planData.map((plan: any) => plan.status)),
    ]);
  }

  function removeInTableFilter(value: any) {
    if (planNameFilter.includes(value)) {
      return setPlanNameFilter(
        planNameFilter.filter((filter) => filter !== value)
      );
    }
    if (amountFilter.includes(value)) {
      return setAmountFilter(amountFilter.filter((filter) => filter !== value));
    }
    if (planCodeFilter.includes(value)) {
      return setPlanCodeFilter(
        planCodeFilter.filter((filter) => filter !== value)
      );
    }
    if (validityFilter.includes(value)) {
      return setValidityFilter(
        validityFilter.filter((filter) => filter !== value)
      );
    }
    if (typeFilter.includes(value)) {
      return setTypeFilter(typeFilter.filter((filter) => filter !== value));
    }
    if (statusFilter.includes(value)) {
      return setStatusFilter(statusFilter.filter((filter) => filter !== value));
    }
  }

  const filteredPlanList = planList;

  const handlePopupModalShow = () => setShowPopupModal(true);
  const handlePopupModalClose = () => setShowPopupModal(false);

  //get all filters selected in forms component and set the same state here
  function getAllSelectedFilters(allSelectedFilters: any[]) {
    setSelectedFilters(allSelectedFilters);
  }

  //get list of filtered plans and set state for display in rows
  function getFilteredPlansByProductPricing(filteredPlans: any[]) {
    //destructuring all filtered plans to send in format, that can be used directly in setPlanList function
    const dest_filteredPlans: any = filteredPlans.map((plan: any) => ({
      id: plan._id,
      name: plan.plan_name,
      code: plan.plan_code,
      amount: plan.prepaid_recharge_amount,
      validity: `${plan.plan_validity} Months`,
      type:
        plan.plan_type.toLowerCase() === planTypes.PREPAID.toLowerCase()
          ? planTypes.PREPAID
          : planTypes.POSTPAID,
      status: capitalizeFirstChar(plan.status.toLowerCase()),
      customer_count: plan.customer_count,
      version: "-",
    }));

    setPlanList(dest_filteredPlans); //set plan list to filtered plans, it will be shown in updated plans rows
  }

  useEffect(() => {
    if (filterPlanCheck) {
      if (
        planNameFilter.length === 0 &&
        statusFilter.length === 0 &&
        validityFilter.length === 0 &&
        planCodeFilter.length === 0 &&
        amountFilter.length === 0
      ) {
        getAllPlans(1);
        setFilterPlanCheck(false);
      }
    }
  }, [
    planNameFilter,
    statusFilter,
    validityFilter,
    typeFilter,
    planCodeFilter,
    amountFilter,
  ]);

  function removeProductPriceFilterTagHandler(filterToBeRemoved: any) {
    const updatedListOfFilters = selectedFilters.filter((filter: any) => {
      return (
        Object.entries(filter).toString() !==
        Object.entries(filterToBeRemoved).toString()
      );
    });

    //re-run filtera-tion logic as selected filters have been updated
    const allFilteredPlans: any[] = allZoopPlans.filter((plan: any) => {
      let check = 0;
      for (const filter of updatedListOfFilters) {
        const unitPrice =
          Object.keys(plan.pricing[filter.productArn]).length > 0
            ? plan.pricing[filter.productArn]?.unit_price
            : typeof plan.pricing[filter.productArn] === "number"
            ? plan.pricing[filter.productArn]
            : 0.0;

        check =
          unitPrice === Number(filter.productPricePoint) ? check + 1 : check;
      }
      return check === updatedListOfFilters.length;
    });

    const finalPlans: any = allFilteredPlans.map((plan: any) => ({
      id: plan._id,
      name: plan.plan_name,
      code: plan.plan_code,
      amount: plan.prepaid_recharge_amount,
      validity: `${plan.plan_validity} Months`,
      type:
        plan.plan_type.toLowerCase() === planTypes.PREPAID.toLowerCase()
          ? planTypes.PREPAID
          : planTypes.POSTPAID,
      status: capitalizeFirstChar(plan.status.toLowerCase()),
      customer_count: plan.customer_count,
      version: "-",
    }));
    setPlanList(finalPlans);

    if (selectedFilters.includes(filterToBeRemoved)) {
      setSelectedFilters(updatedListOfFilters);
    }
  }

  function clearAllProductPriceFilters() {
    const dest_allZoopPlans: any = allZoopPlans.map((plan: any) => ({
      //upon clearing all button -- all zoop plans in DB, should be displayed in rows
      id: plan._id,
      name: plan.plan_name,
      code: plan.plan_code,
      amount: plan.prepaid_recharge_amount,
      validity: `${plan.plan_validity} Months`,
      type:
        plan.plan_type.toLowerCase() === planTypes.PREPAID.toLowerCase()
          ? planTypes.PREPAID
          : planTypes.POSTPAID,
      status: capitalizeFirstChar(plan.status.toLowerCase()),
      customer_count: plan.customer_count,
      version: "-",
    }));
    setPlanList(dest_allZoopPlans);

    setSelectedFilters([]);
    clearAllFilters(); //for clearing column-heading filter tags along with product price filter tags
  }

  function extractTagTitle(filter: any) {
    //return `${filter.productName} ₹ ${filter.productPricePoint}`;
    return (
      <p className={`mb-0 px-2 ${tagStyles.tagTitle}`}>
        {`${filter.productName} ₹ ${filter.productPricePoint}`}
      </p>
    );
  }

  return (
    <div>
      {
        <PopupModal
          show={showPopupModal}
          handleClose={handlePopupModalClose}
          title="Filter Plans By Product Pricing"
        >
          <ProductPriceFilter
            handleFilterPopupClose={handlePopupModalClose}
            fetchAllSelectedFilters={getAllSelectedFilters}
            currentFilters={selectedFilters}
            allProductsPricings={allProductsPricings}
            allZoopPlans={allZoopPlans}
            fetchFilteredPlans={getFilteredPlansByProductPricing}
            removeTagHandler={removeProductPriceFilterTagHandler}
            clearAllHandler={clearAllProductPriceFilters}
          ></ProductPriceFilter>
        </PopupModal>
      }
      <div>
        <Modal
          show={show}
          onHide={handleClose}
          contentClassName={Styles.modalStyle}
          size={"xl"}
          dialogClassName={Styles.planCustomerModal}
        >
          <div className="pl-4 pt-2">
            <Modal.Header className="p-3">
              <div className="d-flex flex-column">
                <div>
                  <h5 className={Styles.planName}>{planName}</h5>
                </div>
                <div>
                  <h4 className={Styles.activeCustomers}>Active Customers</h4>
                </div>
              </div>
              <button
                onClick={handleClose}
                className={`${Styles.closeModalButton}`}
              >
                <Close classes={Styles.close} width="14" height="14" />
              </button>
            </Modal.Header>
          </div>
          <Modal.Body className="p-2">
            <div className={`d-flex flex-column pl-2 ${Styles.customerModal}`}>
              <div>
                {error ? (
                  <p style={{ color: "red" }}>{error}</p>
                ) : (
                  <>
                    {allOrgs.length === 0 ? (
                      <p>No Active customers!</p>
                    ) : (
                      <>
                        <PlanCustomer customersData={allOrgs}></PlanCustomer>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      <div className="p-3">
        <div className="pb-2 d-flex align-items-center">
          <p className={`mb-0 ${Styles.nav_heading}`}>Plans</p>

          {!permissions[`${permissionsType.write}_${grant}`] ? null : (
            <div className="ml-auto px-2">
              <Link
                className="text-decoration-none"
                to="/application/plans/create-plan"
              >
                <Button
                  style={primaryButtonStyle}
                  hoveredStyle={primaryButtonHoverStyle}
                  size={COMPRESSED}
                >
                  Create Plan
                </Button>
              </Link>
            </div>
          )}
        </div>
        <div className="py-4">
          <div className={sharedStyle.card}>
            <div className={`px-4 py-3 ${sharedStyle.cardHeaderBack}`}>
              <div className="d-flex align-items-center">
                <p className={`mb-0 ${sharedStyle.cardHeader}`}>Plans</p>
                <div className="ml-auto px-2">
                  <Button
                    style={primaryButtonStyle}
                    hoveredStyle={primaryButtonHoverStyle}
                    size={COMPRESSED}
                    disabled={loadingFilterByProduct}
                    onClick={handlePopupModalShow}
                  >
                    Filter By Product Pricing
                  </Button>
                </div>
              </div>
            </div>
            {(filter.length > 0 || selectedFilters.length > 0) && (
              <div
                className={`p-2 d-flex align-items-center flex-row-reverse flex-wrap ${Styles.filterBack}`}
              >
                <FilterTagModal
                  allFilters={selectedFilters}
                  extractTagTitleHandler={extractTagTitle}
                  clearAllFiltersHandler={clearAllProductPriceFilters}
                  removeFilterTagHandler={removeProductPriceFilterTagHandler}
                />
                {filter.map((filter: any, index: number) => {
                  return (
                    <div className="p-1" key={index}>
                      <div
                        className={`d-flex align-items-center ${Styles.filterWrapper}`}
                      >
                        <p className={`mb-0 pl-2 ${Styles.filterName}`}>
                          {filter}
                        </p>
                        <div
                          className="px-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => removeInTableFilter(filter)}
                        >
                          <Close classes={Styles.close} width="8" height="8" />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {loading ? (
              <div
                style={{ height: "350px" }}
                className="d-flex align-items-center justify-content-center"
              >
                <Loading loadingColor={ACCENTCOLOR} />
              </div>
            ) : (
              <>
                <div style={{ overflowX: "auto", minHeight: "400px" }}>
                  <table style={{ width: "100%" }}>
                    <thead className={sharedStyle.tableHeaderBack}>
                      <tr>
                        <th
                          className={Styles.name_column}
                          style={{ padding: "15px 15px 15px 25px" }}
                        >
                          <div className="d-flex align-items-center">
                            <p className={`mb-0 ${Styles.tableHeaderText}`}>
                              Name
                            </p>
                            {planNameFilter.length !== 0 &&
                              indicator(planNameFilter)}
                            <div className="px-2">
                              <Sort
                                classes={`${
                                  filterBasedOnSorting.sortType ===
                                    sortTypes.descending &&
                                  filterBasedOnSorting.sortingColumn ===
                                    sortingColumns.plan_name
                                    ? Styles.rotate
                                    : ""
                                } ${
                                  filterBasedOnSorting.sortType !== "" &&
                                  filterBasedOnSorting.sortingColumn ===
                                    sortingColumns.plan_name
                                    ? Styles.active
                                    : Styles.sort
                                }`}
                                click={() =>
                                  applySorting(sortingColumns.plan_name)
                                }
                              />
                            </div>
                            <FilterDropdown
                              width="300px"
                              height="200px"
                              optionArray={listOfPlanNames}
                              filters={planNameFilter}
                              loading={loadingFilter}
                              onSelect={(option: any) => {
                                setActivePageNumber(1);
                                if (planNameFilter.includes(option)) {
                                  setPlanNameFilter(
                                    planNameFilter.filter(
                                      (data: any) => data !== option
                                    )
                                  );
                                  return;
                                }
                                setPlanNameFilter([...planNameFilter, option]);
                              }}
                              toggleReset={planNameFilter.length !== 0}
                              onReset={() => setPlanNameFilter([])}
                            >
                              <div
                                className={`d-flex align-items-center justify-content-center ${Styles.dropdownBack}`}
                                onClick={() => getAllPlanNames()}
                              >
                                <ArrowDown classes={Styles.arrow} />
                              </div>
                            </FilterDropdown>
                          </div>
                        </th>
                        <th className={Styles.code_column}>
                          <div className="d-flex align-items-center">
                            <p className={`mb-0 ${Styles.tableHeaderText}`}>
                              Code
                            </p>
                            {planCodeFilter.length !== 0 &&
                              indicator(planCodeFilter)}
                            <div className="px-2">
                              <FilterDropdown
                                width="300px"
                                height="200px"
                                loading={loadingFilter}
                                optionArray={listOfPlanCodes}
                                filters={planCodeFilter}
                                onSelect={(option: any) => {
                                  setActivePageNumber(1);
                                  if (planCodeFilter.includes(option)) {
                                    setPlanCodeFilter(
                                      planCodeFilter.filter(
                                        (data: any) => data !== option
                                      )
                                    );
                                    return;
                                  }
                                  setPlanCodeFilter([
                                    ...planCodeFilter,
                                    option,
                                  ]);
                                }}
                                toggleReset={planCodeFilter.length !== 0}
                                onReset={() => setPlanCodeFilter([])}
                              >
                                <div
                                  className={`d-flex align-items-center justify-content-center ${Styles.dropdownBack}`}
                                  onClick={() => getAllPlanCodes()}
                                >
                                  <ArrowDown classes={Styles.arrow} />
                                </div>
                              </FilterDropdown>
                            </div>
                          </div>
                        </th>

                        <th className={Styles.version_column}>
                          <div className="d-flex align-items-center">
                            <p className={`mb-0 ${Styles.tableHeaderText}`}>
                              Amount
                            </p>
                            {amountFilter.length !== 0 &&
                              indicator(amountFilter)}
                            <div className="px-2">
                              <Sort
                                classes={`${
                                  filterBasedOnSorting.sortType ===
                                    sortTypes.descending &&
                                  filterBasedOnSorting.sortingColumn ===
                                    sortingColumns.amount
                                    ? Styles.rotate
                                    : ""
                                } ${
                                  filterBasedOnSorting.sortType !== "" &&
                                  filterBasedOnSorting.sortingColumn ===
                                    sortingColumns.amount
                                    ? Styles.active
                                    : Styles.sort
                                }`}
                                click={() =>
                                  applySorting(sortingColumns.amount)
                                }
                              />
                            </div>
                            <FilterDropdown
                              width="300px"
                              height="200px"
                              loading={loadingFilter}
                              optionArray={listOfAmounts}
                              filters={amountFilter}
                              onSelect={(option: any) => {
                                setActivePageNumber(1);
                                if (amountFilter.includes(option)) {
                                  setAmountFilter(
                                    amountFilter.filter(
                                      (data: any) => data !== option
                                    )
                                  );
                                  return;
                                }
                                setAmountFilter([...amountFilter, option]);
                              }}
                              toggleReset={amountFilter.length !== 0}
                              onReset={() => setAmountFilter([])}
                            >
                              <div
                                className={`d-flex align-items-center justify-content-center ${Styles.dropdownBack}`}
                                onClick={() => getAllAmounts()}
                              >
                                <ArrowDown classes={Styles.arrow} />
                              </div>
                            </FilterDropdown>
                          </div>
                        </th>

                        <th className={Styles.validity_column}>
                          <div className="d-flex align-items-center">
                            <p className={`mb-0 ${Styles.tableHeaderText}`}>
                              Validity
                            </p>
                            {validityFilter.length !== 0 &&
                              indicator(validityFilter)}
                            <div className="px-2">
                              <Sort
                                classes={`${
                                  filterBasedOnSorting.sortType ===
                                    sortTypes.descending &&
                                  filterBasedOnSorting.sortingColumn ===
                                    sortingColumns.validity
                                    ? Styles.rotate
                                    : ""
                                } ${
                                  filterBasedOnSorting.sortType !== "" &&
                                  filterBasedOnSorting.sortingColumn ===
                                    sortingColumns.validity
                                    ? Styles.active
                                    : Styles.sort
                                }`}
                                click={() =>
                                  applySorting(sortingColumns.validity)
                                }
                              />
                            </div>
                            <FilterDropdown
                              width="300px"
                              height="200px"
                              loading={loadingFilter}
                              optionArray={listOfValidDurations}
                              filters={validityFilter}
                              onSelect={(option: any) => {
                                setActivePageNumber(1);
                                if (validityFilter.includes(option)) {
                                  setValidityFilter(
                                    validityFilter.filter(
                                      (data: any) => data !== option
                                    )
                                  );
                                  return;
                                }
                                setValidityFilter([...validityFilter, option]);
                              }}
                              toggleReset={validityFilter.length !== 0}
                              onReset={() => setValidityFilter([])}
                            >
                              <div
                                className={`d-flex align-items-center justify-content-center ${Styles.dropdownBack}`}
                                onClick={() => getAllValidDurations()}
                              >
                                <ArrowDown classes={Styles.arrow} />
                              </div>
                            </FilterDropdown>
                          </div>
                        </th>

                        <th className={Styles.type_column}>
                          <div className="d-flex align-items-center">
                            <p
                              className={`mb-0 mr-2 ${Styles.tableHeaderText}`}
                            >
                              Type
                            </p>
                            <FilterDropdown
                              width="300px"
                              height="200px"
                              optionArray={listOfPlanTypes}
                              filters={typeFilter}
                              loading={loadingFilter}
                              onSelect={(option: any) => {
                                setActivePageNumber(1);
                                if (typeFilter.includes(option)) {
                                  setTypeFilter(
                                    typeFilter.filter(
                                      (data: any) => data !== option
                                    )
                                  );
                                  return;
                                }
                                setTypeFilter([...typeFilter, option]);
                              }}
                              toggleReset={typeFilter.length !== 0}
                              onReset={() => setTypeFilter([])}
                            >
                              <div
                                className={`d-flex align-items-center justify-content-center ${Styles.dropdownBack}`}
                                onClick={() => {
                                  getAllPlanTypes();
                                }}
                              >
                                <ArrowDown classes={Styles.arrow} />
                              </div>
                            </FilterDropdown>
                          </div>
                        </th>

                        <th className={Styles.status_column}>
                          <div className="d-flex align-items-center">
                            <p
                              className={`mb-0 mr-2 ${Styles.tableHeaderText}`}
                            >
                              Status
                            </p>
                            <FilterDropdown
                              width="300px"
                              height="200px"
                              loading={loadingFilter}
                              optionArray={listOfPlanStatuses}
                              filters={statusFilter}
                              onSelect={(option: any) => {
                                setActivePageNumber(1);
                                if (statusFilter.includes(option)) {
                                  setStatusFilter(
                                    statusFilter.filter(
                                      (data: any) => data !== option
                                    )
                                  );
                                  return;
                                }
                                setStatusFilter([...statusFilter, option]);
                              }}
                              toggleReset={statusFilter.length !== 0}
                              onReset={() => setStatusFilter([])}
                            >
                              <div
                                className={`d-flex align-items-center justify-content-center ${Styles.dropdownBack}`}
                                onClick={() => getAllPlanStatuses()}
                              >
                                <ArrowDown classes={Styles.arrow} />
                              </div>
                            </FilterDropdown>
                          </div>
                        </th>

                        <th className={Styles.customer_count_column}>
                          <p
                            className={`mb-0 ${Styles.tableHeaderText}`}
                            style={{ textAlign: "center" }}
                          >
                            Customer Count
                          </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody className={Styles.tableWrapper}>
                      {filteredPlanList.length > 0 ? (
                        filteredPlanList.map((plan: any, index: number) => {
                          return (
                            <Link
                              key={plan.id}
                              to={`/application/plans/${plan.id}`}
                              className={"text-decoration-none"}
                            >
                              <tr
                                style={{ borderBottom: "1px solid #eceeef" }}
                                className={Styles.table_row_wrapper}
                              >
                                <td
                                  className={Styles.name_column}
                                  style={{ padding: "15px 15px 15px 25px" }}
                                >
                                  <p className={`mb-0 ${Styles.tableBodyText}`}>
                                    {plan?.name}
                                  </p>
                                </td>
                                <td className={Styles.code_column}>
                                  <p className={`mb-0 ${Styles.tableBodyText}`}>
                                    {plan?.code}
                                  </p>
                                </td>
                                <td className={Styles.version_column}>
                                  <p className={`mb-0 ${Styles.tableBodyText}`}>
                                    {plan?.amount}
                                  </p>
                                </td>
                                <td className={Styles.validity_column}>
                                  <p className={`mb-0 ${Styles.tableBodyText}`}>
                                    {plan?.validity}
                                  </p>
                                </td>
                                <td className={Styles.type_column}>
                                  <p className={`mb-0 ${Styles.tableBodyText}`}>
                                    {plan?.type}
                                  </p>
                                </td>
                                <td className={Styles.status_column}>
                                  <p className={`mb-0 ${Styles.tableBodyText}`}>
                                    {plan?.status}
                                  </p>
                                </td>
                                <td className={Styles.customer_count_column}>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p
                                      className={`mb-0 ml-5 ${Styles.tableBodyText} ${Styles.customerInfoLink}`}
                                      onClick={(e) =>
                                        handleShow(
                                          e,
                                          plan?.id,
                                          plan?.name,
                                          index
                                        )
                                      }
                                    >
                                      {customersLoading &&
                                      customerListIndex === index ? (
                                        <div className={Styles.loader}></div>
                                      ) : (
                                        plan?.customer_count
                                      )}
                                    </p>
                                    <div
                                      className={`${Styles.rotateRight} ml-2`}
                                    >
                                      <ArrowDown className={Styles.svgFill} />
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </Link>
                          );
                        })
                      ) : (
                        <tr
                          style={{ height: "400px" }}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <td className="mb-0">No Plans found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="px-4 py-3 d-flex align-items-center">
                  <p className={`mb-0 ${Styles.numberOfRows}`}>
                    {filteredPlanList.length} rows per page
                  </p>
                  {filteredPlanList.length > 0 && (
                    <div className="py-2 ml-auto">
                      <Pagination
                        activePageNumber={activePageNumber}
                        total={planLength}
                        postPerPage={postPerPage}
                        navigateToPage={(value: number) => {
                          setActivePageNumber(value);
                          getAllPlans(value);
                        }}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Plans;
